<template>
  <div>
    <Modal
      v-model="skuTaskModal"
      width="800"
    >
      <div
        slot="header"
        class="text-center"
      ><span class="p-r-5">{{taskInfo.resourceCode}}</span><span class="p-r-5">({{ taskInfo.skuName }})</span>包含资源查看</div>
      <Table
        stripe
        max-height="620"
        size="small"
        :data="list"
        :columns="columns"
        :loading="tableLoading"
      ></Table>
    </Modal>
  </div>
</template>

<script>
import { getSkuPlanDetailList } from '@/api/msp/plan'
export default {
  data () {
    return {
      skuTaskModal: false,
      taskInfo: {},
      tableLoading: false,
      list: [],
      columns: [
        {
          title: '资源编号',
          key: 'resourceCode',
          minWidth: 120,
          render: (h, { row }) => {
            return h('span', row.resourceCode || '/')
          }
        },
        {
          title: '位置',
          align: 'center',
          minWidth: 140,
          render: (h, { row }) => {
            return h('span', row.stationName + ' / ' + row.positionName)
          }
        },
        { title: '任务类型', align: 'center', minWidth: 140, key: 'typeName' },
        { title: '用途', align: 'center', minWidth: 140, key: 'adTypeName' },
        { title: '设备名称', align: 'center', minWidth: 140, key: 'devicemodelName' }

      ]
    }
  },
  methods: {
    showModal (data = null) {
      this.list = []
      if (data) {
        this.taskInfo = data
        this.getInfo()
        this.skuTaskModal = true
      } else {
        this.$Notice.error({ desc: '非法访问，缺少必要参数' })
      }
    },
    async getInfo () {
      this.tableLoading = true
      const postData = { skuId: this.taskInfo.skuId, taskId: this.taskInfo.taskId }
      const result = await getSkuPlanDetailList(postData).finally(() => { this.tableLoading = false })
      this.list = result || []
    }
  }
}
</script>
