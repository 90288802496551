<template>
  <div>
    <Modal v-model="show" width="800" footer-hide>
      <div slot="header" class="text-center">
        <span class="p-r-10">{{ taskInfo.location }}</span><span class="p-r-10">{{ taskInfo.resourceCode }}</span>
      </div>
      <material-box
        :taskitemFiles="taskitemFiles"
        :taskitemAttachs="taskitemAttachs"
      />
    </Modal>
  </div>
</template>

<script>
import MaterialBox from './MaterialBox.vue'
export default {
  components: { MaterialBox },
  data () {
    return {
      show: false,
      taskInfo: {},
      taskitemFiles: [],
      taskitemAttachs: []
    }
  },
  methods: {
    showModal (data = null) {
      if (data) {
        this.show = true
        this.taskInfo = data
        this.taskitemFiles = data.taskitemFiles
        this.taskitemAttachs = data.taskitemAttachs
      } else {
        this.$Modal.error({ desc: '非法访问，缺少必要参数' })
      }
    }
  }
}
</script>
