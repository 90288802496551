<template>
  <div>
    <Modal
      v-model="MesasureModal"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >详情查看</p>
      <div class="m-b-10">
        <task-basic-info
          :info="taskInfo"
          :checkType="checkType"
        />
      </div>
      <div>
        <h4 class="workplatform-title m-t-10 m-b-10">设置设备关联的主题媒介</h4>
        <Row>
          <i-col span="24">
            <Select
              v-model="chooseProductSkuId"
              size="small"
              placeholder="主题媒介清单"
              :clearable="true"
              style="width:300px"
            >
              <Option
                v-for="item in themeList"
                :key="item.productskuId"
                :value="item.productskuId"
              >{{item.productName}}-{{item.productskuName}}</Option>
            </Select>
            <Button
              type="primary"
              size="small"
              class="m-l-10"
              :disabled="(!chooseProductSkuId || fileLocation==='')"
              @click="handleRelationTheme"
            >关联媒介</Button>

            <Button
              v-if="svgHtml.length>0"
              type="success"
              size="small"
              class="m-l-10"
              @click="handleDownloadMR"
            >下载MR套稿</Button>
          </i-col>
        </Row>

        <h4 class="workplatform-title m-t-10 m-b-10">MR套稿</h4>
        <div
          v-if="svgHtml"
          v-html="svgHtml"
          class="svg-station-content "
          style="height:500px;"
          id="svg-station-content"
        ></div>
        <div
          v-else
          class="default-content"
        >
          <p style="font-size: xx-large;">抱歉，无法加载MR套稿！</p>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import svgPanZoom from 'svg-pan-zoom'
import { downloadFilePostRequest } from '@/utils/download'
import { getSkuList, setMR } from '@/api/product/productfix'

import TaskBasicInfo from './TaskBasicInfo.vue'
export default {
  props: {
    checkType: { // 类型：1-质检，2-对账
      type: Number,
      default: 1
    }
  },
  components: { TaskBasicInfo },
  data () {
    return {
      MesasureModal: false,
      taskInfo: {},
      spinShow: false,
      svgHtml: '',
      showBindTheme: true,
      themeList: [],
      chooseProductSkuId: null,
      fileLocation: ''
    }
  },
  methods: {
    showModal (data = null) {
      if (data) {
        this.taskInfo = data
        this.MesasureModal = true
        this.initData()
      } else {
        this.$Notice.error({ desc: '非法访问，缺少必要参数' })
      }
    },
    initData () {
      this.svgHtml = ''
      this.fileLocation = ''
      this.loadSkuList(this.taskInfo.deviceId)

      if (this.taskInfo.taskitemAttachs.length > 0 && this.taskInfo.taskitemAttachs[0].mimeType === 'image/svg+xml') {
        for (let index = 0; index < this.taskInfo.taskitemAttachs.length; index++) {
          const element = this.taskInfo.taskitemAttachs[index]
          if (element.mimeType === 'image/svg+xml') {
            this.fileLocation = element.fileKey
            axios.get(element.fileKey).then(res => {
              this.svgHtml = res.data
              this.$nextTick(() => {
                const cuurentSvgElement = document.getElementById('svg-station-content').firstElementChild
                // 设置svg呈现在画板上的尺寸
                cuurentSvgElement.setAttribute('width', '100%')
                cuurentSvgElement.setAttribute('height', '100%')
                svgPanZoom(cuurentSvgElement, {
                  panEnabled: true,
                  controlIconsEnabled: false,
                  zoomEnabled: true,
                  dblClickZoomEnabled: true,
                  center: true
                }).zoom(1.3)
              })
            })
            break
          }
        }
      }
    },
    loadSkuList (deviceId) { // 加载设备所属的主题媒介集合
      getSkuList({ deviceId: deviceId, enabled: true }).then(res => {
        this.themeList = res
        this.chooseProductSkuId = res.length > 0 ? res[0].productskuId : null
      })
    },
    handleRelationTheme () {
      const postData = {
        fileLocation: this.fileLocation,
        skuId: this.chooseProductSkuId
      }

      setMR(postData).then(res => {
        this.$Notice.success({ desc: '关联主题媒介成功！' })
        this.MesasureModal = false
      })
    },
    handleDownloadMR () {
      const fileName = 'MR套稿(' + this.MR.domainName + '_' + this.MR.resourceCode + ').png'
      const postData = {
        svg: this.HTMLEncode(this.svgHtml)
      }
      downloadFilePostRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-draw/svg/downloadsvgpng', postData, fileName)
    },
    HTMLEncode (html) {
      var temp = document.createElement('div');
      (temp.textContent != null) ? (temp.textContent = html) : (temp.innerText = html)
      var output = temp.innerHTML
      temp = null
      return output
    }
  }
}
</script>
