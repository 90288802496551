<template>
  <div>
    <Tabs value="list" @on-click="onClickTab">
      <TabPane
        label="列表质检"
        name="list"
      >
        <list-check ref="list" />
      </TabPane>
      <TabPane
        label="大图质检"
        name="picture"
      >
        <picture-check ref="picture" />
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import ListCheck from './ListCheck.vue'
import PictureCheck from './PictureCheck.vue'
export default {
  data () {
    return {
      tabName: 'list'
    }
  },
  components: { ListCheck, PictureCheck },
  mounted () {
    this.$nextTick(() => {
      this.$refs.list.initData()
    })
  },
  methods: {
    onClickTab (name) {
      // 防止多次点击重复调用
      if (this.tabName !== name) {
        this.$nextTick(() => {
          this.$refs[name].initData()
        })
      }
      this.tabName = name
    }
  }
}
</script>
