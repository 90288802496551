<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="3">
        <Select
          size="small"
          placeholder="选择状态"
          v-model="query.qualityTesting"
          transfer
          clearable
          @on-change="onChangeStatus"
        >
          <i-option
            v-for="item in statusArray"
            :key="'status_' + item.key"
            :value="item.key"
          >
            {{ item.value }}
          </i-option>
        </Select>
      </i-col>
      <i-col span="3">
        <DatePicker
          transfer
          size="small"
          class="text-black"
          v-model="actionDateRange"
          format="yyyy-MM-dd"
          type="daterange"
          style="width: 100%;"
          placeholder="选择作业日期"
          @on-change="onChangeActionDate"
        ></DatePicker>
      </i-col>
      <i-col span="3">
        <Select
          size="small"
          v-model="query.assetId"
          :clearable="true"
          placeholder="选择资产"
          transfer
          @on-change="onChangeAsset"
        >
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_' + item.id"
            :value="item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          size="small"
          v-model="query.stationId"
          :clearable="true"
          transfer
          placeholder="选择站点"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_' + item.id"
            :value="item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          size="small"
          v-model="query.taskitemType"
          :clearable="true"
          transfer
          placeholder="选择任务类型"
          @on-change="onChangeTaskType"
        >
          <Option
            v-for="item in taskitemTypeArray"
            :key="'type_' + item.value"
            :value="item.value"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          size="small"
          v-model="query.adType"
          :clearable="true"
          transfer
          placeholder="选择用途"
        >
          <Option
            v-for="item in adTypeArray"
            :key="'adType_' + item.value"
            :value="item.value"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <i-input
          v-model="query.keyword"
          size="small"
          placeholder="关键字：资源编号、客户名称"
        ></i-input>
      </i-col>
      <i-col span="2">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          @click="onSearch"
        >搜索</Button>
      </i-col>
    </Row>
    <div class="m-b-10">
      <Button
        size="small"
        type="success"
        class="m-r-5"
        :disabled="btnConfirm"
        @click="onConfirmCheck"
      >通过质检</Button>
      <Button
        size="small"
        type="primary"
        class="m-r-5"
        @click="onDownloadTask"
      >下载工单明细</Button>
    </div>
    <div>
      <Table
        stripe
        max-height="620"
        size="small"
        :data="tableList"
        :columns="tableColumns"
        :loading="tableLoading"
        @on-selection-change="onSelectionTable"
      ></Table>
      <Row :gutter="8">
        <i-col span="6">
          <div
            class="m-t-5"
            style="line-height:25px;"
          >当前已选中<span style="color:#ff9900;padding:0 3px">{{ selectedItem.length }}</span>条</div>
        </i-col>
        <i-col span="18">
          <div class="paging_style">
            <Page
              size="small"
              :total="total"
              :page-size="query.pageSize"
              show-total
              show-elevator
              show-sizer
              :page-size-opts="pagesizeSetting"
              @on-page-size-change='onChangePageSize'
              :current="query.pageNumber"
              @on-change="onChangePageNum"
            ></Page>
          </div>
        </i-col>
      </Row>
    </div>
    <material-view v-if="materialView" ref="materialView"/>
    <task-detail ref="taskDetail" :checkType="curListTab" @updateTable="getTableData"/>
    <measure-task-detail ref="measureTaskDetail" :checkType="curListTab"/>
    <relation-task ref="relationTask"></relation-task>
    <train-list ref="trainList" @updateTable="getTableData"></train-list>
    <set-files ref="setFiles" @updateTable="getTableData"/>
    <sku-task-detail ref="skuTask"/>
    <download-task ref="downloadTask" :taskitemTypeArray="taskitemTypeArray"/>
  </div>
</template>

<script>
import { indexMixins } from '../../mixins'
import { getQualityTaskitemPage } from '@/api/msp/plan'
import { confirmTaskFinish } from '@/api/msp/taskitem'
// compontents
import MaterialView from '../common/MaterialView.vue'
import TaskDetail from '../common/TaskDetail.vue'
import RelationTask from '../common/RelationTask.vue'
import TrainList from './TrainList.vue'
import SetFiles from './SetFiles.vue'
import MeasureTaskDetail from '../common/MeasureTaskDetail.vue'
import SkuTaskDetail from '@/components/checkAccept/SkuTaskDetail.vue'
import DownloadTask from '../common/DownloadTask.vue'
export default {
  mixins: [indexMixins],
  components: { MaterialView, TaskDetail, RelationTask, TrainList, SetFiles, MeasureTaskDetail, SkuTaskDetail, DownloadTask },
  data () {
    return {
      statusArray: [
        { key: 0, value: '未质检' },
        { key: 1, value: '已质检' }
      ],
      query: {
        pageNumber: 1,
        pageSize: 15,
        qualityTesting: 0,
        startActionDate: '',
        endActionDate: '',
        assetId: undefined,
        stationId: undefined,
        taskitemType: undefined,
        adType: undefined,
        keyword: ''
      },
      tableLoading: false,
      tableList: [],
      total: 0,
      tableColumns: [],
      showSetFiles: false
    }
  },
  computed: {
    btnConfirm () {
      return !this.selectedItem.length
    }
  },
  methods: {
    /**
     * 初始化
     */
    initData () {
      this.curListTab = 1
      this.showSetFiles = false
      this.generateColumns()
      this.getCompanyAssetArray()
      this.getTaskitemTypeData()
      this.getTableData()
    },
    /**
     * 动态生成列表
     */
    generateColumns () {
      const columns = ['selection', 'code', 'position', 'typeName', 'actionDate', 'adTypeName', 'skuName', 'createCompanyName', 'supplierCompany', 'actionFile', 'customer', 'schedule', 'qualityTestTime', 'status', 'remark', 'operate']
      const showColumns = []
      columns.forEach(col => showColumns.push(this.defineColumns[col]))
      this.tableColumns = showColumns
    },
    /**
     * 获取列表数据
     */
    async getTableData () {
      this.tableLoading = true
      const result = await getQualityTaskitemPage(this.query).finally(() => { this.tableLoading = false })

      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
        this.tableList.forEach(item => {
          item._disabled = item.qualityTesting
          item._checked = this.selectedItem.some(x => x === item.id)
        })
      } else {
        this.tableList = []
        this.total = 0
      }
    },

    onChangeStatus () {
      this.query.pageNumber = 1
      this.selectedItem = []
      this.getTableData()
    },
    onSearch () {
      this.query.pageNumber = 1
      this.selectedItem = []
      this.getTableData()
    },
    onChangePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.getTableData()
    },
    onChangePageNum (page) {
      this.query.pageNumber = page
      this.selectedItem = []
      this.getTableData()
    },
    onConfirmCheck () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并通过质检？',
        onOk: () => {
          const postData = {
            taskitemIds: JSON.stringify(this.selectedItem),
            produceTaskitemIds: ''
          }
          confirmTaskFinish(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.selectedItem = []
              this.getTableData()
            }
          })
        }
      })
    },
    /**
     * 显示关联任务
     */
    handleShowTask (params) {
      this.$nextTick(() => {
        this.$refs.relationTask.initShow({ taskitemId: params.id, taskitemType: params.taskitemType })
      })
    },
    /**
     * 确认列车
     * @param params 参数
     */
    onConfirmTrain (params) {
      this.$nextTick(() => {
        this.$refs.trainList.initData({ assetId: params.assetId, taskitemId: params.id })
      })
    },
    /**
     * 素材补充
     * @param params 参数
     */
    handleSetFiles (params) {
      this.$nextTick(() => {
        this.$refs.setFiles.showModal(params)
      })
    }
  }
}
</script>
