<template>
  <div>
    <Modal
      v-model="showDetail"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >详情查看</p>
      <div class="m-b-10">
        <task-basic-info
          :info="taskInfo"
          :checkType="checkType"
        />
      </div>
      <div v-if="checkType===1&&rephoto" class="m-b-10 text-right">
        <Button
          type="warning"
          size="small"
          @click="onRephoto"
        >重拍</Button>
      </div>
      <div>
        <material-box
          :taskitemFiles="taskInfo.taskitemFiles"
          :taskitemAttachs="taskInfo.taskitemAttachs"
        />
      </div>

    </Modal>
  </div>
</template>

<script>
import { optionMixins } from '../../mixins/option'
import TaskBasicInfo from './TaskBasicInfo.vue'
import MaterialBox from './MaterialBox.vue'
export default {
  mixins: [optionMixins],
  components: { TaskBasicInfo, MaterialBox },
  props: {
    checkType: { // 类型：1-质检，2-对账
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      showDetail: false,
      taskInfo: {}
    }
  },
  computed: {
    rephoto () {
      if (this.taskInfo.rephoto === 0 && !this.taskInfo.qualityTesting && this.taskInfo.taskitemAttachs && this.taskInfo.taskitemAttachs.length) { // 未质检，未执行重拍，有反馈素材
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    showModal (data = null) {
      if (data) {
        this.taskInfo = data
        this.curTaskitemIds = [this.taskInfo.id]
        this.showDetail = true
      } else {
        this.$Notice.error({ desc: '非法访问，缺少必要参数' })
      }
    },
    updateTable () {
      this.showDetail = false
      this.$emit('updateTable')
    }
  }
}
</script>
